import styles from './RegistryPage.module.css'
import RatesTime from './../../EmployeesPage/RatesTime/RatesTime';
import { useState } from 'react';

function RegistryPage() {
  const [workHours, setWorkHours] = useState({
    morning: {
      firstInput: '',
      secondInput: '',
      thirdInput: '',
      fourthInput: ''
    },
    day: {
      firstInput: '',
      secondInput: '',
      thirdInput: '',
      fourthInput: ''
    },
    evening: {
      firstInput: '',
      secondInput: '',
      thirdInput: '',
      fourthInput: ''
    },
    night: {
      firstInput: '',
      secondInput: '',
      thirdInput: '',
      fourthInput: ''
    }
  })
  const [info, setInfo] = useState({
    name: '',
    number1c: '',
    description: '',
  })

  let stylesForRate = {
      gap: '16px',
      disable: false,
      first: {
        first: {
          title: 'Ставка (утро)',
          placeholder: '1.200 руб. ч.',
          width: '232px'
        },
        second: {
          title: 'Ставка пер. (утро)',
          placeholder: '1.200 руб. ч.',
          width: '232px'
        },
        third: {
          title: 'Выходной',
          placeholder: '1.200 руб. ч.',
          width: '232px'
        },
        fourth: {
          title: 'Переработка вых.',
          placeholder: '1.200 руб. ч.',
          width: '232px'
        },
      },
      second: {
        first: {
          title: 'Ставка (день)',
          placeholder: '1.200 руб. ч.',
          width: '232px'
        },
        second: {
          title: 'Ставка пер. (день)',
          placeholder: '1.200 руб. ч.',
          width: '232px'
        },
        third: {
          title: 'Выходной',
          placeholder: '8 ч.',
          width: '232px'
        },
        fourth: {
          title: 'Переработка вых.',
          placeholder: '1.200 руб. ч.',
          width: '232px'
        },
      },
      third: {
        first: {
          title: 'Ставка (вечер)',
          placeholder: '1.200 руб. ч.',
          width: '232px'
        },
        second: {
          title: 'Ставка пер. (вечер)',
          placeholder: '1.200 руб. ч.',
          width: '232px'
        },
        third: {
          title: 'Выходной',
          placeholder: '8 ч.',
          width: '232px'
        },
        fourth: {
          title: 'Переработка вых.',
          placeholder: '1.200 руб. ч.',
          width: '232px'
        },
      },
      fourth: {
        first: {
          title: 'Ставка (ночь)',
          placeholder: '1.200 руб. ч.',
          width: '232px'
        },
        second: {
          title: 'Ставка пер. (ночь)',
          placeholder: '1.200 руб. ч.',
          width: '232px'
        },
        third: {
          title: 'Выходной',
          placeholder: '8 ч.',
          width: '232px'
        },
        fourth: {
          title: 'Переработка вых.',
          placeholder: '1.200 руб. ч.',
          width: '232px'
        },
      },
  }

  const updateWorkHours = (period, field, value) => {
    setWorkHours(prevWorkHours => ({
      ...prevWorkHours,
      [period]: {
        ...prevWorkHours[period],
        [field]: value
      }
    }));
  };

  const updateInfo = (field, value) => {
    setInfo((prevInfo) => ({
        ...prevInfo,
        [field]: value,
    }));
  };

  const createJobTitle = () => {
    console.log(info)
    console.log(workHours)
  };

  return (
    <div className={styles.container}>
        <div className={styles.first_part}>
            <button className={styles.first_container}>
            Название должности
            </button>
            {/* { appointments.map((el, index) => {
              return 
            })
              } */}
        </div>
      
        <div className={styles.second_part}>
            <div className={styles.second_title_container}>
                <div className={styles.second_title_content}>
                    <div className={styles.second_input} style={{flex: 2}}>
                        <span>Название</span>
                        <input type="text"  placeholder='Название должности'  onChange={(e) => updateInfo('name', e.target.value)}/>
                    </div>
                    <div className={styles.second_input} style={{flex: 1}}>
                        <span>Номер 1С </span>
                        <input type="text"  placeholder='Введите номер'  onChange={(e) => updateInfo('number1c', e.target.value)}/>
                    </div>
                </div>
                <div className={styles.second_title_content}>
                    <div className={styles.second_input}  style={{marginTop: '25px', width: '100%'}}>
                        <span>Описание</span>
                        <textarea type="text" style={{height: '123px', padding: '12px 16px 12px 16px;'}} placeholder='Введите текст'  onChange={(e) => updateInfo('description', e.target.value)}/>
                    </div>
                </div>
            </div>
            <div className={styles.rates_container}>
                <RatesTime content={stylesForRate} fields={workHours} handleFields={updateWorkHours}/>
            </div>
            <button className={styles.rates_button} onClick={createJobTitle}>
                Сохранить
            </button>
        </div>
    </div>
  );
}

export default RegistryPage;
  