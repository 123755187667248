import {useState, useRef, useEffect} from 'react';
import styles from './EmployeesPage.module.css'
import filter_header_icon from '../../assets/EmployeesPage/filter_header_icon.png'
import delete_icon from '../../assets/EmployeesPage/delete_icon.png'
import EmployeeChangeModal from '../../components/EmployeesPage/EmployeeChangeModal/EmployeeChangeModal';
import useClickOutside from './../../components/UseClickOutside/UseClickOutside';
import EmployeeAddModal from '../../components/EmployeesPage/EmployeeAddModal/EmployeeAddModal';
import {getWorkersRequest} from "../../api/requests";
import {get} from "axios";

function EmployeesPage() {
    const [workers, setWorkers] = useState([]);
    const [currentFilter, setCurrentFilter] = useState('Все')
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [employeeChangeModalShow, setEmployeeChangeShow] = useState(false)
    const [employeeAddModalShow, setEmployeeAddModalShow] = useState(false)

    let resetWorkers = async () => {
        const workers = await getWorkersRequest(1)
        setWorkers(workers)
        return workers
    }

    useEffect(() => {
        resetWorkers()
    }, []);    

    const modalFilterRef = useRef();
    useClickOutside(modalFilterRef, () => setShowFilterModal(false));

    let openEmployeeChange = (val) => {
        setEmployeeChangeShow(val)
    }

    let openEmployeeAdd = (val) => {
        setEmployeeAddModalShow(val)
    }

    return (
        <div className={styles.employees_container}>
            <div className={styles.header_container}>
                <div className={styles.filter_container} ref={modalFilterRef}>
                    Фильтр:
                    <span className={styles.current_filter}
                          onClick={() => setShowFilterModal(!showFilterModal)}>{currentFilter} <img
                        src={filter_header_icon} alt=""/></span>
                    {showFilterModal && <div className={styles.filter_content}>
                        <div className={styles.filter_content_header}>
                            {currentFilter}
                        </div>
                        <hr/>
                        <div className={styles.filter_content_other}>
                            <span>Инд. условия</span>
                            <span>Черновик</span>
                            <span>Уволен</span>
                        </div>
                        <hr/>
                        <div className={styles.jobtitle_choose}>
                            <div className={styles.jobtitle_choose_head}>
                                Должность
                            </div>
                            <div className={styles.jobtitle_choose_head}>
                            </div>
                        </div>
                    </div>}
                </div>
                <button className={styles.add_employee} onClick={() => setEmployeeAddModalShow(true)}>
                    Добавить сотрудника
                </button>
            </div>
            <div className={styles.main_container}>
                <div className={styles.main_header}>
                    <div className={styles.main_header_select}>
                        <input type="checkbox"/>
                        <span>
                    1 выбран
                </span>
                    </div>
                    <div className={styles.main_header_delete}>
                        <img src={delete_icon} alt=""/>
                        Удалить
                    </div>
                </div>

                <div className={styles.main_content_container}>
                    {workers.map((el, index) => {
                        return (
                            <div className={styles.elem_container} key={el.email}
                                 style={{borderTop: index === 0 ? '1px solid rgb(235, 239, 242)' : ''}}>
                                <div className={styles.elem_name}>
                                    <input type="checkbox" name="" id=""/>
                                    <img src="" alt=""/>
                                    {el.lastname} {el.firstname} {el.secondname}
                                </div>
                                <div className={styles.elem_email}>
                                    {el.email}
                                </div>
                                <div className={styles.elem_change}>
                            <span onClick={() => openEmployeeChange(true)}>
                                Редактировать
                            </span>
                                    <span>
                                Удалить
                            </span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {employeeChangeModalShow ? <EmployeeChangeModal closeModal={openEmployeeChange} resetWorkers={resetWorkers}/> : <></>}
            {employeeAddModalShow ? <EmployeeAddModal closeModal={openEmployeeAdd} resetWorkers={resetWorkers}/> : <></>}
        </div>
    );
}

export default EmployeesPage;
