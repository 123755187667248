import styles from './EmployeeChangeModal.module.css'
import modal_header_icon from '../../../assets/EmployeesPage/modal_header_icon.png'
import ModalInfo from './../ModalInfo/ModalInfo';
import RatesTime from './../RatesTime/RatesTime';
import JobTitle from '../JobTitle/JobTitle';
import { useState } from 'react';

function EmployeeChangeModal({closeModal, resetWorkers}) {

  // let stylesForRate = {
  //   gap: '70px',
  //   disable: false,
  //   first: {
  //     first: {
  //       title: 'Ставка (утро)',
  //       placeholder: '1.200 руб. ч.',
  //       width: '163px'
  //     },
  //     second: {
  //       title: 'Ставка переработки (утро)',
  //       placeholder: '1.200 руб. ч.',
  //       width: '163px'
  //     },
  //     third: {
  //       title: 'Кол. часов (утро)',
  //       placeholder: '8 ч.',
  //       width: '143px'
  //     },
  //     fourth: {
  //       title: 'Период (утро)',
  //       placeholder: 'С__:__ До__:__',
  //       width: '181px'
  //     },
  //   },
  //   second: {
  //     first: {
  //       title: 'Ставка (день)',
  //       placeholder: '1.200 руб. ч.',
  //       width: '163px'
  //     },
  //     second: {
  //       title: 'Ставка переработки (день)',
  //       placeholder: '1.200 руб. ч.',
  //       width: '163px'
  //     },
  //     third: {
  //       title: 'Кол. часов (день)',
  //       placeholder: '8 ч.',
  //       width: '143px'
  //     },
  //     fourth: {
  //       title: 'Период (день)',
  //       placeholder: 'С__:__ До__:__',
  //       width: '181px'
  //     },
  //   },
  //   third: {
  //     first: {
  //       title: 'Ставка (вечер)',
  //       placeholder: '1.200 руб. ч.',
  //       width: '163px'
  //     },
  //     second: {
  //       title: 'Ставка переработки (вечер)',
  //       placeholder: '1.200 руб. ч.',
  //       width: '163px'
  //     },
  //     third: {
  //       title: 'Кол. часов (вечер)',
  //       placeholder: '8 ч.',
  //       width: '143px'
  //     },
  //     fourth: {
  //       title: 'Период (вечер)',
  //       placeholder: 'С__:__ До__:__',
  //       width: '181px'
  //     },
  //   },
  //   fourth: {
  //     first: {
  //       title: 'Ставка (ночь)',
  //       placeholder: '1.200 руб. ч.',
  //       width: '163px'
  //     },
  //     second: {
  //       title: 'Ставка переработки (ночь)',
  //       placeholder: '1.200 руб. ч.',
  //       width: '163px'
  //     },
  //     third: {
  //       title: 'Кол. часов (ночь)',
  //       placeholder: '8 ч.',
  //       width: '143px'
  //     },
  //     fourth: {
  //       title: 'Период (ночь)',
  //       placeholder: 'С__:__ До__:__',
  //       width: '181px'
  //     },
  //   },
  // }
  const [choosedJob, setChoosedJob] = useState([]);
  const [jobs, setJobs] = useState(['Директор', 'Работник', 'Элита (Рабек :)', 'Повар']);

  const addJob = (val) => {
    setChoosedJob((prevChoosedJob) => 
      prevChoosedJob.includes(val) 
        ? prevChoosedJob.filter((el) => el !== val) 
        : [...prevChoosedJob, val]
    );
  };

  return (
    <div className={styles.background_container} onClick={() => closeModal(false)}>
      <div className={styles.main_container} onClick={(event) => event.stopPropagation()}>
        <div className={styles.main_header}>
          <span>
            Пользователь
          </span>
          <img src={modal_header_icon} alt="" onClick={() => closeModal(false)}/>
        </div>
        <div className={styles.main_content}>
          <div className={styles.content_header}>
            <div className={styles.header_name}>
              <img src="" alt="" />
              <span>
                Жиркое Дария Валерьевна
              </span>
            </div>
            <div className={styles.header_jobtitle_container}>
              <JobTitle jobs={jobs} choosedJob={choosedJob} addJob={addJob} />
            </div>
          </div>
          <ModalInfo />
          {/* <div className={styles.rates_container}>
            <div className={styles.rates_title}>Ставки</div>
            <div className={styles.rates_second}>
              <div className={styles.rates_second_name}>
                <span>Повар</span>
                <span>Шеф повар</span>
              </div>
              <div className={styles.rates_second_checkbox}>
                <input type="checkbox" />
                Индивидуальные условия
              </div>
            </div>
            <div className={styles.rates_title}>
              <RatesTime content={stylesForRate}/>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default EmployeeChangeModal;
