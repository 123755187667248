import axios from './axiosConfig';

const getHeaders = async () => {
  const accessTokenHeaders = {
    "Authorization" : "Bearer " + localStorage.getItem('access_token')
  }
  
  const refreshTokenHeaders = {
    "Authorization" : "Bearer " + localStorage.getItem('refresh_token')
  }
  
  try {
    const pingResponse = await axios.get('/auth/ping', {headers: accessTokenHeaders})
    if(pingResponse.data.success) {
      return accessTokenHeaders
    }
  } catch(error) {
    if(error.response.status == 401) {
      const refreshResponse = await axios.get('/auth/refresh', {headers: refreshTokenHeaders})
      localStorage.setItem('access_token', refreshResponse.data.accessToken)
      localStorage.setItem('refresh_token', refreshResponse.data.refreshToken)
      return {
        "Authorization" : "Bearer " + localStorage.getItem('access_token')
      }
    }
    throw new Error('Неизвестная ошибка')
  }
}

export const authRequest = async (data)  => {
  try {
    const response = await axios.post('/auth/signin', data)
    return response.data
  } catch (error) {
    console.log(error)
    if(error.response.status && error.response.data.message) {
      return {
        "statusCode": error.response.status,
        "error": error.response.data.message
      }
    }
    throw new Error('Неизвестная ошибка')
  }
};

export const getAppointmentsRequest = async () => {
  try {
    const response = await axios.get('/company/appointment', {headers: await getHeaders()})
    return response.data
  } catch(error) {
    if(error.response.status && error.response.data.message) {
      return {
        "statusCode": error.response.status,
        "error": error.response.data.message
      }
    }
    throw new Error('Неизвестная ошибка')
  }
}

export const getWorkersRequest = async (companyId) => {
  try {
    const response = await axios.get('/worker/company/' + companyId, {headers: await getHeaders()});
    return response.data
  } catch (error) {
    if(error.response.status && error.response.data.message) {
      return {
        "statusCode": error.response.status,
        "error": error.response.data.message
      }
    }
    throw new Error('Неизвестная ошибка')
  }
};

export const createWorkerRequest = async (data) => {
  try {
    const response = await axios.post('/worker', data, { headers: await getHeaders()});
    return response.data
  } catch (error) {
    if(error.response.status && error.response.data.message) {
      return {
        "statusCode": error.response.status,
        "error": error.response.data.message
      }
    }
    throw new Error('Неизвестная ошибка')
  }
};

export const updateWorkerRequest = async (data) => {
  try {
    const response = await axios.post('/worker', data, { headers: await getHeaders()});
    return response.data
  } catch (error) {
    if(error.response.status && error.response.data.message) {
      return {
        "statusCode": error.response.status,
        "error": error.response.data.message
      }
    }
    throw new Error('Неизвестная ошибка')
  }
};

export const createItem = async (itemData) => {
  const response = await axios.post('/items', itemData);
  return response.data;
};

export const updateItem = async (id, itemData) => {
  const response = await axios.put(`/items/${id}`, itemData);
  return response.data;
};

export const deleteItem = async (id) => {
  const response = await axios.delete(`/items/${id}`);
  return response.data;
};
