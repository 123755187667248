import DayTable from './../DayTable/DayTable';
import styles from './ParametersPage.module.css'

function ParametersPage() {
  let time = {
    first: {
      first: '12:30',
      second: '13:30'
    },
    second: {
      first: '12:30',
      second: '13:30'
    },
    third: {
      first: '12:30',
      second: '13:30'
    },
    fourth: {
      first: '12:30',
      second: '16:30'
    },
  }
    return (
      <div className={styles.container}>
        <DayTable time={time}/>

        <div className={styles.times_container}>
          <div className={styles.time_container}>
            <div className={styles.time_container_title}>
              Утро
            </div>
            <div className={styles.time_container_input}>
              <span>Кол. часов (утро)</span>
              <input type="text" placeholder='8 ч.'/>
            </div>
            <div className={styles.time_container_input}>
              <span>Период (утро)</span>
              <input type="text" placeholder='С __:__   До __:__'/>
            </div>
          </div>
          <div className={styles.time_container}>
            <div className={styles.time_container_title}>
              День
            </div>
            <div className={styles.time_container_input}>
              <span>Кол. часов (день)</span>
              <input type="text" placeholder='8 ч.'/>
            </div>
            <div className={styles.time_container_input}>
              <span>Период (день)</span>
              <input type="text" placeholder='С __:__   До __:__'/>
            </div>
          </div>
          <div className={styles.time_container}>
            <div className={styles.time_container_title}>
              Вечер
            </div>
            <div className={styles.time_container_input}>
              <span>Кол. часов (вечер)</span>
              <input type="text" placeholder='8 ч.'/>
            </div>
            <div className={styles.time_container_input}>
              <span>Период (вечер)</span>
              <input type="text" placeholder='С __:__   До __:__'/>
            </div>
          </div>
          <div className={styles.time_container}>
            <div className={styles.time_container_title}>
              Ночь
            </div>
            <div className={styles.time_container_input}>
              <span>Кол. часов (ночь)</span>
              <input type="text" placeholder='8 ч.'/>
            </div>
            <div className={styles.time_container_input}>
              <span>Период (ночь)</span>
              <input type="text" placeholder='С __:__   До __:__'/>
            </div>
          </div>
        </div>
        <button>
          Сохранить
        </button>
      </div>
    );
  }
  
  export default ParametersPage;
  