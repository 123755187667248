import React, { useState } from 'react';
import styles from './ModalInfo.module.css';
import Calendar from './../../Calendar/Calendar';

function ModalInfo({ info, handleInfo }) {

    const formatDate = (date) => {
        if (!date) return ''; // Если дата не задана, вернуть пустую строку
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0'); // Получаем день и добавляем ноль впереди
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Получаем месяц (0-11), добавляем 1 и добавляем ноль впереди
        const year = d.getFullYear(); // Получаем полный год

        return `${day}.${month}.${year}`; // Возвращаем отформатированную дату
    };
    const setDateFunc = (val) => {
        handleInfo('birthdate', formatDate(val));
        console.log(formatDate(val))
    };

  return (
    <div className={styles.container}>
      <div className={styles.container_first}>
        <div className={styles.input_container}>
          <div className={styles.input_title}>Телефон</div>
          <input
            type="text"
            value={info.phone} // Отображаемое значение
            onChange={(e) => handleInfo('phone', e.target.value)}
            placeholder="8 (___) ___ - __ - __"
          />
        </div>
        
        <div className={styles.input_container}>
          <div className={styles.input_title}>Email</div>
          <input
            type="text"
            value={info.email}
            onChange={(e) => handleInfo('email', e.target.value)}
            placeholder="Email"
          />
        </div>
      </div>
      <div className={styles.container_second}>
        <div className={styles.input_container}>
          <div className={styles.input_title}>Дата рождения</div>
          <Calendar setDateFunc={setDateFunc} isInput={true}/>
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_title}>Номер паспорта</div>
          <input
            type="text"
            value={info.passportNumber}
            onChange={(e) => handleInfo('passportNumber', e.target.value)}
            placeholder="Номер паспорта"
          />
        </div>
        <div className={styles.input_container}>
          <div className={styles.input_title}>Номер 1С</div>
          <input
            type="text"
            value={info.number1c}
            onChange={(e) => handleInfo('number1c', e.target.value)}
            placeholder="Номер 1С"
          />
        </div>
      </div>
    </div>
  );
}

export default ModalInfo;
