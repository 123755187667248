import styles from './header.module.css';
import main_icon from '../../assets/Header/main_icon.png'
import { NavLink } from 'react-router-dom';
import { ReactComponent as HomeIcon } from '../../assets/Header/home_icon.svg';
import { ReactComponent as StatisticsIcon } from '../../assets/Header/statistics_icon.svg';
import { ReactComponent as EmployeesIcon } from '../../assets/Header/employees_icon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/Header/settings_icon.svg';

function Header() {
    return (
        <header> 
            <NavLink to="/">
                <img src={main_icon} alt="" />   
            </NavLink>
            <div className={styles.icons_and_avatar}>
                <ul>
                    <NavLink
                    to="/"
                    style={({ isActive }) => ({
                        color: isActive ? '#6366F1' : '#8893A7',
                        fill: isActive ? '#6366F1' : '#8893A7',
                    })}>
                        <li>
                            <HomeIcon className={styles.svgicon} />
                            Главная
                        </li>
                    </NavLink>
                    <NavLink
                    to="/statistics"
                    style={({ isActive }) => ({
                        color: isActive ? '#6366F1' : '#8893A7',
                        fill: isActive ? '#6366F1' : '#8893A7',
                    })}>
                        <li>
                            <StatisticsIcon className={styles.svgicon} />
                            Статистика
                        </li>
                    </NavLink>
                    <NavLink
                    to="/employees"
                    style={({ isActive }) => ({
                        color: isActive ? '#6366F1' : '#8893A7',
                        fill: isActive ? '#6366F1' : '#8893A7',
                    })}>
                        <li>
                            <EmployeesIcon className={styles.svgicon} />
                            Сотрудники
                        </li>
                    </NavLink>
                    <NavLink
                    to="/settings/profile"
                    style={({ isActive }) => ({
                        color: isActive ? '#6366F1' : '#8893A7',
                        fill: isActive ? '#6366F1' : '#8893A7',
                    })}>
                        <li>
                            <SettingsIcon className={styles.svgicon} />
                            Настройки
                        </li>
                    </NavLink>
                </ul>
                <img className={styles.avatar} src="" alt="" />
            </div>
        </header>
    );
}

export default Header;
