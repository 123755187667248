import { useEffect } from 'react';
import styles from './DayTable.module.css'

function DayTable({time}) {

    let hours = ['0', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24',]
    let sticks = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24',]

    useEffect(() => {
      const convertTimeToPercentage = (timeString) => {
        const element = document.querySelectorAll('#hours_container li')[timeString.split(':')[0]];
        // console.log(document.querySelectorAll('#hours_container li')[timeString.split(':')[0]])
        const container = document.querySelector('#hours_container');
    
        const elementRect = element.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
    
        const distanceToLeftEdge = elementRect.left - containerRect.left;
        const distanceToRightEdge = containerRect.right - elementRect.right;
    
        // console.log('Расстояние до левого края контейнера:', distanceToLeftEdge);
        // console.log('Расстояние до правого края контейнера:', distanceToRightEdge);
        return {start: distanceToLeftEdge, end: distanceToRightEdge}
      };
    
      // Элементы загружены, выполняем логику
      const timeIntervals = Object.values(time).map((interval) => ({
        start: convertTimeToPercentage(interval.first),
        end: convertTimeToPercentage(interval.second),
      }));
      console.log('timeIntervals')
      console.log(timeIntervals)

      timeIntervals.forEach((el, index) => {
        console.log(el.end.end - el.start.start)
        console.log(document.querySelector(`#active_line_container_${index}`))

        document.querySelector(`#active_line_container_${index}`).style.left = `${el.start.start}px`
        document.querySelector(`#active_line_container_${index}`).style.width = `${el.start.start - el.end.end}px`
      })
    }, [time]);
    
    return (
      <div className={styles.table_container}>
        <ul id='hours_container'>
          {hours.map((el) => {
            return (
              <li>
                {el}
              </li>
            )
          })}
        </ul>
        <div className={styles.sticks_container}>
          {sticks.map(() => {
            return (
              <div className={styles.stick}>
              </div>
            )
          })}
        </div>
        <div className={styles.empty_lines_container}>
          <div className={styles.empty_line_container}>
            <div className={styles.active_line_container} id='active_line_container_0'></div>
          </div>
          <div className={styles.empty_line_container}>
            <div className={styles.active_line_container} id='active_line_container_1' style={{background: '#FF9500'}}></div>
          </div>
          <div className={styles.empty_line_container}>
            <div className={styles.active_line_container} id='active_line_container_2' style={{background: '#BFBFBF'}}></div>
          </div>
          <div className={styles.empty_line_container}>
            <div className={styles.active_line_container} id='active_line_container_3' style={{background: '#3E3E3E'}}></div>
          </div>
        </div>
        <div className={styles.colors_container}>
          <div className={styles.color_container}>
            <div className={styles.color} style={{background: '#DFE709'}}></div>
            День
          </div>
          <div className={styles.color_container}>
            <div className={styles.color} style={{background: '#FF9500'}}></div>
            Утро
          </div>
          <div className={styles.color_container}>
            <div className={styles.color} style={{background: '#BFBFBF'}}></div>
            Вечер
          </div>
          <div className={styles.color_container}>
            <div className={styles.color} style={{background: '#3E3E3E'}}></div>
            Ночь
          </div>
        </div>
      </div>
    );
}

export default DayTable;
  