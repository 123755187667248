import styles from './RatesTime.module.css'

function RatesTime({content, fields, handleFields}) {

  
  return (
    <div className={styles.container} style={{color: content.disable ? 'rgb(122, 122, 122)' : 'rgb(26, 33, 43)'}}>
      <div className={styles.content_container} style={{background: "rgb(242, 249, 255)"}}>
        <div className={styles.content_title}>
          Утро
        </div>
        <div className={styles.content_input_container} style={{gap: content.gap}}>
          <div className={styles.content_input}>
            <span>{content.first.first.title}</span>
            <input type="text"  onChange={(e) => handleFields('morning', 'firstInput', e.target.value)} style={{maxWidth: content.first.first.width, border: content.disable &&'1px solid rgb(201, 201, 201)'}} placeholder={content.first.first.placeholder}/>
          </div>
          <div className={styles.content_input}>
            <span>{content.first.second.title}</span>
            <input type="text" onChange={(e) => handleFields('morning', 'secondInput', e.target.value)} style={{maxWidth: content.first.second.width, border: content.disable &&'1px solid rgb(201, 201, 201)'}} placeholder={content.first.second.placeholder}/>
          </div>
          <div className={styles.content_input}>
            <span>{content.first.third.title}</span>
            <input type="text"  onChange={(e) => handleFields('morning', 'thirdInput', e.target.value)} style={{maxWidth: content.first.third.width, border: content.disable &&'1px solid rgb(201, 201, 201)'}} placeholder={content.first.third.placeholder}/>
          </div>
          <div className={styles.content_input}>
            <span>{content.first.fourth.title}</span>
            <input type="text" onChange={(e) => handleFields('morning', 'fourthInput', e.target.value)}  style={{maxWidth: content.first.fourth.width, border: content.disable &&'1px solid rgb(201, 201, 201)'}} placeholder={content.first.fourth.placeholder}/>
          </div>
        </div>
      </div>
      <div className={styles.content_container}>
        <div className={styles.content_title}>
          День
        </div>
        <div className={styles.content_input_container} style={{gap: content.gap}}>
          <div className={styles.content_input}>
            <span>{content.second.first.title}</span>
            <input type="text" onChange={(e) => handleFields('day', 'firstInput', e.target.value)} style={{maxWidth: content.second.first.width, border: content.disable &&'1px solid rgb(201, 201, 201)'}} placeholder={content.second.first.placeholder}/>
          </div>
          <div className={styles.content_input}>
            <span>{content.second.second.title}</span>
            <input type="text" onChange={(e) => handleFields('day', 'secondInput', e.target.value)} style={{maxWidth: content.second.second.width, border: content.disable &&'1px solid rgb(201, 201, 201)'}} placeholder={content.second.second.placeholder}/>
          </div>
          <div className={styles.content_input}>
            <span>{content.second.third.title}</span>
            <input type="text" onChange={(e) => handleFields('day', 'thirdInput', e.target.value)} style={{maxWidth: content.second.third.width, border: content.disable &&'1px solid rgb(201, 201, 201)'}} placeholder={content.second.third.placeholder}/>
          </div>
          <div className={styles.content_input}>
            <span>{content.second.fourth.title}</span>
            <input type="text" onChange={(e) => handleFields('day', 'fourthInput', e.target.value)} style={{maxWidth: content.second.fourth.width, border: content.disable &&'1px solid rgb(201, 201, 201)'}} placeholder={content.second.fourth.placeholder}/>
          </div>
        </div>
      </div>
      <div className={styles.content_container} style={{background: "rgb(242, 249, 255)"}}>
        <div className={styles.content_title}>
          Вечер
        </div>
        <div className={styles.content_input_container} style={{gap: content.gap}}>
          <div className={styles.content_input}>
            <span>{content.third.first.title}</span>
            <input type="text"  onChange={(e) => handleFields('evening', 'firstInput', e.target.value)} style={{maxWidth: content.third.first.width, border: content.disable &&'1px solid rgb(201, 201, 201)'}} placeholder={content.third.first.placeholder}/>
          </div>
          <div className={styles.content_input}>
            <span>{content.third.second.title}</span>
            <input type="text"  onChange={(e) => handleFields('evening', 'secondInput', e.target.value)} style={{maxWidth: content.third.second.width, border: content.disable &&'1px solid rgb(201, 201, 201)'}} placeholder={content.third.second.placeholder}/>
          </div>
          <div className={styles.content_input}>
            <span>{content.third.third.title}</span>
            <input type="text"  onChange={(e) => handleFields('evening', 'thirdInput', e.target.value)} style={{maxWidth: content.third.third.width, border: content.disable &&'1px solid rgb(201, 201, 201)'}} placeholder={content.third.third.placeholder}/>
          </div>
          <div className={styles.content_input}>
            <span>{content.third.fourth.title}</span>
            <input type="text"  onChange={(e) => handleFields('evening', 'fourthInput', e.target.value)} style={{maxWidth: content.third.fourth.width, border: content.disable &&'1px solid rgb(201, 201, 201)'}} placeholder={content.third.fourth.placeholder}/>
          </div>
        </div>
      </div>
      <div className={styles.content_container}>
        <div className={styles.content_title}>
          Ночь
        </div>
        <div className={styles.content_input_container} style={{gap: content.gap}}>
          <div className={styles.content_input}>
            <span>{content.fourth.first.title}</span>
            <input type="text"  onChange={(e) => handleFields('night', 'firstInput', e.target.value)} style={{maxWidth: content.fourth.first.width, border: content.disable &&'1px solid rgb(201, 201, 201)'}} placeholder={content.fourth.first.placeholder}/>
          </div>
          <div className={styles.content_input}>
            <span>{content.fourth.second.title}</span>
            <input type="text" onChange={(e) => handleFields('night', 'secondInput', e.target.value)} style={{maxWidth: content.fourth.second.width, border: content.disable &&'1px solid rgb(201, 201, 201)'}} placeholder={content.fourth.second.placeholder}/>
          </div>
          <div className={styles.content_input}>
            <span>{content.fourth.third.title}</span>
            <input type="text"  onChange={(e) => handleFields('night', 'thirdInput', e.target.value)} style={{maxWidth: content.fourth.third.width, border: content.disable &&'1px solid rgb(201, 201, 201)'}} placeholder={content.fourth.third.placeholder}/>
          </div>
          <div className={styles.content_input}>
            <span>{content.fourth.fourth.title}</span>
            <input type="text"  onChange={(e) => handleFields('night', 'fourthInput', e.target.value)} style={{maxWidth: content.fourth.fourth.width, border: content.disable &&'1px solid rgb(201, 201, 201)'}} placeholder={content.fourth.fourth.placeholder}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RatesTime;
