import React, { useState, useRef, useEffect } from 'react';
import styles from './MainPage.module.css'; // Импорт стилей
import Calendar from './../../components/Calendar/Calendar.jsx';
import filter_icon from '../../assets/MainPage/filter_icon.svg'
import Table from '../../components/Table/Table.jsx';
import useClickOutside from '../../components/UseClickOutside/UseClickOutside.jsx';
import {Navigate, useNavigate} from "react-router-dom";

function MainPage() {
  const [date, setDate] = useState(new Date())
  const [showFilterModal, setShowFilterModal] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      if (!isAuthorized()) {
        navigate('/login')
      }
    };

    fetchData();
  }, []);

  const modalFilterRef = useRef();
  useClickOutside(modalFilterRef, () => {if(showFilterModal){setShowFilterModal(false)}});

  function isAuthorized() {
    return !!(localStorage.getItem('access_token') && localStorage.getItem('refresh_token'));
  }

  let setDateFunc = (val) => {
    setDate(val)
  }

  let people = ['Фирсанова Елена Андреевна', 'Дмитров Евгений Юрьевич', 'Фирсанова лена Андреевна']

  return (
    <div className={styles.main_container}>
      <div className={styles.main_header}>
        <Calendar setDateFunc={setDateFunc} />
        <div ref={modalFilterRef} className={styles.main_header_filter} onClick={() => setShowFilterModal(true)}>
          <img src={filter_icon} alt=""/>
          {showFilterModal && <div className={styles.modal_filter_container}>
            <div className={styles.modal_filter_filt}>
              По умолчанию
            </div>
            <hr style={{margin: "10px 0"}}/>
            <div className={styles.modal_filter_filt}>
              По дате
            </div>
            <hr style={{margin: "10px 0"}}/>
            <div className={styles.modal_filter_status}>
              По статусу
            </div>
            <hr style={{margin: "10px 0"}}/>
          </div>} 
        </div>
      </div>
      <hr />
      <div className={styles.main_content}>
        <Table leftPart={people} widthPart="48px" canAddJobTitle='true' date={date} tableNumber='0'/>
        <Table leftPart={people} widthPart="48px" canAddJobTitle='true' date={date} tableNumber='1'/>
      </div>
    </div>
  );
}

export default MainPage;